import './home.css';
import StorieslistView from './categorieslist';

const Home = () => {
    return (
        <>
        <main>
        <section className='home-hero'>
            <div className='home-hero__container'>
                <h2>שיחות על דרך, כוחות, ועל מה שעוזר לצמוח</h2>
                <p>ראיונות, רעיונות ופעילויות עם האנשים שמוצאים ומעצימים יחד עם הילדים את הכוחות שלהם</p>
            </div>

        </section> 
        <StorieslistView/>
        </main> 
        </>  
    )
}

export default Home