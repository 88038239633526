import React, { useState } from "react";
import Createproject from "./create";
import Home from "./home";
import StorieslistView from "./categorieslist";
import CategoryView from "./categoryview";
import ProjectView from "./show";
import ProjectEdit from "./edit";
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import './App.css'
function App() {
  const [iaAuth, setIsAuth] = useState(false);
  return (
    <>
 
    <Router>
      <header className="upperNav">
      <Link className="logo" to="/">
      <svg width="81" height="26" viewBox="0 0 81 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M79.5 21H71.5H62C58 21 57.7 19.1 56.5 17.5C55 15.5 51.5 16 50 17.5C48.5 19 49.5 22 46.5 22C44.1 22 36.5 22 33 22" stroke="#00B91D" stroke-width="2" stroke-linecap="round"/>
        <path d="M42.144 15.0255C42.944 16.6255 47.3106 16.3588 49.144 16.5255C49.9773 16.1922 53.1439 14.6922 55.1439 16.0255C56.6439 17.0255 62.1439 17.5255 63.1439 16.0255C65.0154 13.2182 60.3769 14.7925 63.1439 12.0255C64.6439 10.5255 62.1439 8.02551 59.6439 9.02551C57.3227 9.95399 56.1439 10.5255 56.6439 9.02551C57.1439 7.52552 57.1439 0.525513 55.1439 1.02551C53.1439 1.52551 52.1439 10.0255 52.1439 9.02551C52.1439 8.02551 48.6439 6.52551 46.6439 8.02551C44.644 9.52551 47.6439 12.5255 46.6439 11.5255C45.6439 10.5255 41.144 13.0255 42.144 15.0255Z" fill="#73E21C" stroke="#00B91D" stroke-width="2"/>
        <path d="M28.5477 13C29.5477 16.6667 29.1477 22.8 23.5477 22C19.5692 21.4316 18.1749 18.9251 17.8043 16.5M18.0477 12C17.8318 12.8633 17.5229 14.6582 17.8043 16.5M23.5477 12C24.0477 13.1667 24.5477 15.7 22.5477 16.5C20.5477 17.3 18.5521 16.8333 17.8043 16.5M14.0477 11V16M11.0477 22C11.8811 19.1667 11.5477 12 7.04768 12C1.54774 12 0.0478098 16.5 1.54776 22.5" stroke="#00B91D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="19" cy="9" r="1" fill="#00B91D"/>
        <circle cx="26" cy="25" r="1" fill="#00B91D"/>
      </svg>
      </Link>
     <h1>שיח - אתר שיחות</h1>
      <nav >
        <Link to="/">בית</Link>
      </nav>
      </header>
      <Routes>
      <Route exact path="/" element={<Home/>}/>
        <Route exact path="/storylists" element={<StorieslistView/>}/>
        <Route path="/category/:category" element={<CategoryView/>}/>
        <Route path="/Create/" element={<Createproject/>}/>
        <Route path="/show/:id" element={<ProjectView/>}/>
        <Route path="/Editstory/:id" element={<ProjectEdit/>}/>
      </Routes>
    </Router>


    <footer className="footer" id="footer">
        <div>&#169; כל הזכויות שמורות 
</div>
     
    </footer>
 </>
  );
  
}

export default App;