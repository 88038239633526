const StoryTags = ({story}) => {
    return (
        <>
            <ul>
            { story.storyTags.map(tag =>(
                <li className={tag.value}>{tag.label}</li> ) )}
            </ul>
        </>
    )
}

export default StoryTags;