
import React , {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import fb from "./firebase";

import StoryTags from "./components/StoryTags";




const DB =fb.firestore()
const storieslist = DB.collection('stories');

const StoriesList = (props) => {
    const[stories, setStories] = useState([]);
    useEffect(() =>{
        const unsubscribe = storieslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            // Update state
            setStories(data);
           });
  
          // Detach listener
          return unsubscribe;
    }, []);

    const Deletestory = (id)=> {
        storieslist.doc(id).delete().then(() => {
            alert("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    };


    
    return (
        <div className="storiesGallery">
            {stories.filter(doc => doc.storyCategory.includes(props.storycategory))
            .sort((a, b) => a.order - b.order)
            .map(story=>(
                    <Link key={story.id}  to={"/show/"+story.id}  className="card card-horizontal">        
                        <div className='img-container'>
                        <img alt={story.storyTitle} src={story.storyIconUrls}></img>
                        </div>
            
                        <div className='storiesGallery__info'>
                            <h3 className='storiesGallery__title'>{story.storyTitle}</h3>
                            <h4 className='storiesGallery__contactName'><span>עם </span>{story.contactName}</h4>
                            <p className='storiesGallery__description'>{story.storyDescription}</p>
                            <StoryTags story={story} />
                        </div>
                 </Link>                          
            ))}
        </div>
    )
};

export default StoriesList;