import React , {useState} from "react";
import { useParams} from 'react-router-dom';
import fb from "./firebase";
import StoryTags from "./components/StoryTags";
const DB =fb.firestore()
const storieslist = DB.collection('stories');


const ProjectView = ()=> {
    const {id} = useParams();
    const[stories, Setstories] = useState([]);
    storieslist.doc(id).get().then((snapshot) => {
        const data = snapshot.data()
        Setstories(data);
    });
    return(
        <div className="story-view" key={stories.id}>
            <div className="story-view__hero" style={{backgroundImage: `url(${stories.storyImageUrls})`}}>
                <div className="story-view__hero-content">
                <h2 className="story-view__title">{stories.storyTitle}</h2>
  
                <p className="story-view__description">{stories.storyDescription}</p>
                <ul className="story-view__contact-info">
                {stories.contactPhone && 
                    <li>
                        <a href={`tel:${stories.contactPhone}`} className="info__phone" title="Phone call"><span className="srOnly">Phone</span>
                            <svg width="50px" height="50px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="6.5" y="3" width="12" height="18" rx="3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.5 18.5C12.3665 18.5 12.2409 18.448 12.1465 18.3536C12.052 18.2591 12 18.1336 12 18C12 17.9008 12.0291 17.8047 12.0843 17.7222C12.1394 17.6397 12.217 17.576 12.3086 17.5381C12.3696 17.5128 12.434 17.5 12.5 17.5C12.5327 17.5 12.5655 17.5032 12.5975 17.5096C12.6949 17.529 12.7834 17.5763 12.8536 17.6464C12.9237 17.7166 12.971 17.8051 12.9904 17.9025C13.0098 17.9998 12.9999 18.0997 12.9619 18.1913C12.924 18.283 12.8603 18.3606 12.7778 18.4157C12.6953 18.4709 12.5992 18.5 12.5 18.5Z" />
                                <path d="M12.5 19C12.2348 19 11.9804 18.8946 11.7929 18.7071C11.6054 18.5196 11.5 18.2652 11.5 18C11.5 17.8022 11.5586 17.6089 11.6685 17.4444C11.7784 17.28 11.9346 17.1518 12.1173 17.0761C12.3 17.0004 12.5011 16.9806 12.6951 17.0192C12.8891 17.0578 13.0673 17.153 13.2071 17.2929C13.347 17.4327 13.4422 17.6109 13.4808 17.8049C13.5194 17.9989 13.4996 18.2 13.4239 18.3827C13.3482 18.5654 13.22 18.7216 13.0556 18.8315C12.8911 18.9414 12.6978 19 12.5 19Z" />
                            </svg>
                        </a>
                        </li>
                }
                {stories.contactPhone && 
                    <li>
                        <a title="Chat on WhatsApp" href={`https://wa.me/${stories.contactPhone}`} className="info__whatsupp"><span className="srOnly">whatsupp</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="-3 -1 30 30" id="whatsapp"><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-name="<Group>"><path d="M12 .5a11.5 11.5 0 0 0-9.54 17.92L.5 23.5l5.23-1.87A11.5 11.5 0 1 0 12 .5Z" data-name="<Path>"></path><path d="M9.5 14.5c1.3 1.3 4.17 3 5.5 3a2.53 2.53 0 0 0 2.5-2v-1s-1.23-.6-2-1-2 1-2 1A6.52 6.52 0 0 1 11 13a6.52 6.52 0 0 1-1.5-2.5s1.4-1.23 1-2-1-2-1-2h-1a2.53 2.53 0 0 0-2 2.5c0 1.33 1.7 4.2 3 5.5Z" data-name="<Path>"></path></g></svg>
                        </a>
                        </li>
                }
                {stories.contactEmail &&
                    <li>
                        <a href= {`mailto:${stories.contactEmail}`} className="info__email" title="send an email"><span className="srOnly" title="Chat on WhatsApp">Email</span>
                            <svg width="50px" height="50px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 8L17.4392 9.97822C15.454 11.0811 14.4614 11.6326 13.4102 11.8488C12.4798 12.0401 11.5202 12.0401 10.5898 11.8488C9.53864 11.6326 8.54603 11.0811 6.5608 9.97822L3 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </li> 
                }
                    {stories.contactWebsite && 
                    <li><a href={stories.contactWebsite} className="info__web" title="Pesonal Web site"><span className="srOnly">go to pesonal Web site</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M21.337.458l-3.937 3.936c-2.449-1.466-5.67-1.144-7.78.966l-4.261 4.262c-2.11 2.11-2.433 5.331-.968 7.78l-3.935 3.935c-.609.609-.609 1.597 0 2.207s1.598.609 2.207 0l3.935-3.935c2.449 1.465 5.67 1.144 7.78-.967l4.261-4.261c2.11-2.11 2.433-5.331.968-7.781l3.936-3.936c.609-.609.609-1.597 0-2.207s-1.597-.609-2.206.001zm-4.862 11.757l-4.261 4.261c-.895.894-2.176 1.169-3.31.827l1.632-1.632c.609-.609.609-1.597 0-2.207s-1.598-.609-2.207 0l-1.631 1.632c-.343-1.133-.067-2.415.826-3.309l4.261-4.261c.895-.894 2.176-1.169 3.31-.827l-1.631 1.63c-.609.609-.609 1.597 0 2.207s1.598.609 2.207 0l1.63-1.63c.343 1.133.067 2.415-.826 3.309z"/></svg></a></li>
                }

                {stories.contactFacebook && 
                    <li><a href={stories.contactFacebook}  className="info__facebook" title="Facebook"><span className="srOnly">go to social media: Facebook</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg></a></li>
                }

                {stories.contactLinkedin && 
                    <li><a href={stories.contactLinkedin} className="info__linkedin" title="Linkedin"><span className="srOnly">go to social media: Linkedin</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg> </a></li>
                }

                {stories.contactInstagram && 
                    <li><a href={stories.contactInstagram} className="info__instagram" title="Instagram"><span className="srOnly">go to social media: Instagram</span><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30">    <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"/></svg></a></li>
                }

                {stories.contactX && 
                    <li><a href={stories.contactX} className="info__X"><span className="srOnly" title="X">go to social media: X</span><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50"><path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"/></svg></a></li>
                }
                
            </ul>
                </div>
            </div>

            <div>
                <div className="story-view__text" dangerouslySetInnerHTML={{__html: stories.storyTextValue}} />          
            </div>
           <div className="story-view__contact-container">
                <div className="story-view__contact-card">
                    <div className="story-view__contact-card__imgContainer">
                    <img className="story-view__profileIMG" src={stories.profileImageUrls} alt={stories.contactName}/>
                    </div>
                    <div className="story-view__contact-card__textInfo">
                        <div className="story-view__contact-card__text">
                            <h3>{stories.contactName}</h3>
                            <p dangerouslySetInnerHTML={{__html: stories.contactDescription}}/> 
                        </div>
                    </div>
            </div>
           </div>
        </div>
    );
};
export default ProjectView;