import React , {useState, useEffect} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';

import './create.css'

import {   
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    list
 } from "firebase/storage";
import { v4 } from "uuid";
import fb from "./firebase";
import { storage } from "./firebaseConfig";

const DB = fb.firestore()
const storieslist = DB.collection('stories');



const Createstory = () => {
    const[storyCategory, setStoryCategory] = useState("");
    const[storyTitle, setStoryTitle] = useState("");
    const[storyDescription, setStoryDescription] = useState("");
    const[location, setLocation] = useState("");
    const[date, setDate] = useState("");
    const [storyTextValue, setStoryTextValue] = useState("");
    const [storyTags, setStoryTags] = useState([]);
    const [storyImageUpload, setStoryImageUpload] = useState(null);
    const [storyImageUrls, setStoryImageUrls] = useState(""); 
    const [profileImageUrls, setProfileImageUrls] = useState("");
    const [storyIconUrls, setStoryIconUrls] = useState("");
//    contact details
    const [profileImageUpload, setProfileImageUpload] = useState(null);
    const[contactName, setContactName] = useState("");
    const[contactDescription, setContactDescription] = useState("");
    const[contactPhone, setContactPhone] = useState("");
    const[contactEmail, setContactEmail] = useState("");
    const[contactWebsite, setContactWebsilte] = useState("");
    // contact media
    const[contactFacebook, setContactFacebook] = useState("");
    const[contactLinkedin, setContactLinkedin] = useState("");
    const[contactX, setContactX] = useState("");
    const[contactInstagram, setContactInstagram] = useState("");
   



    const imagesListRef = ref(storage, "images/");
    const profileImagesListRef = ref(storage, "profiles/");
    const uploadFile = () => {
      if (storyImageUpload == null) return;
      const imageRef = ref(storage, `images/${storyImageUpload.name + v4()}`);
      uploadBytes(imageRef, storyImageUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
         
          //setStoryImageUrls((prev) => [...prev, url]);
          setStoryImageUrls(() => [url]);
          setStoryIconUrls(() => [url]);
        });
                }).then((docRef)=> {
            alert("file successfully uploaded")
        }).catch((error) => {
            console.error("error:", error);
        });
     
    };

    const uploadProfileImage = () => {
        if (profileImageUpload == null) return;
        const profileImageRef = ref(storage, `profiles/${profileImageUpload.name + v4()}`);
        uploadBytes(profileImageRef, profileImageUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
           
            //setStoryImageUrls((prev) => [...prev, url]);
            setProfileImageUrls(() => [url]);
          });
                  }).then((docRef)=> {
              alert("file successfully uploaded")
          }).catch((error) => {
              console.error("error:", error);
          });
      };
  
    const categoryOptions = [
        { value: 'activities', label:'חוגים ופעילויות'},
        { value: 'Associations', label:'עמותות'}
        // etc
      ];
   

    const tagOptions = [
        { value: 'ads', label: 'ידידותי לאוטיזם'},
        { value: 'physical', label: 'מותאם למוגבלות פיזית'},
        { value: 'education', label: 'השכלה טיפולית'},
        // etc
      ];
   
      const categoryLabel = storyCategory.label;

    const submit =(e)=> {    
        e.preventDefault();
        storieslist.add ({
            storyCategory: categoryLabel,
            storyTitle: storyTitle,
            storyDescription: storyDescription,
            location: location,
            date: date,
            storyTextValue: storyTextValue,
            storyTags: storyTags,
            storyImageUrls : storyImageUrls,
            storyIconUrls : storyIconUrls,
            profileImageUrls : profileImageUrls,
            contactName : contactName,
            contactDescription : contactDescription,
            contactPhone : contactPhone,
            contactEmail : contactEmail,
            contactWebsite : contactWebsite,   

            contactFacebook : contactFacebook,   
            contactLinkedin : contactLinkedin,
            contactX : contactX ,
            contactInstagram : contactInstagram ,

        }).then((docRef)=> {
            alert("data successfully submitted")
        }).catch((error) => {
            console.error("error:", error);
        });
       
    }

    

    return(
        <div className="createStory">
        <form onSubmit={(event) => {submit(event)}}>  
        <legend>Add Story</legend>  
        <div className="createStory__unit">
        <Select 
            className="comboBox"
            options={categoryOptions}
            value={storyCategory}
            onChange={setStoryCategory}
            isSingle={true}
        />
        </div>
        <div className="createStory__unit">
             <label for="titleForm_story">כותרת</label>
            <input type="text" id="titleForm_story" placeholder="כותרת" onChange={(e)=>{setStoryTitle(e.target.value)}} required />
        </div>
        <div className="createStory__unit">
        <label for="contentForm_story">תאור</label>
            <input name="content" id="contentForm_story" type="text" placeholder="תאור" onChange={(e)=>{setStoryDescription(e.target.value)}} required /> 
        </div>
        <div className="createStory__unit">
        <label for="locationForm_story">מקום</label>
            <input name="location" id="ocationForm_story" type="text" placeholder="מיקום" onChange={(e)=>{setLocation(e.target.value)}} /> 
        </div>
        <div className="createStory__unit" >
        <label for="dateForm_story" >זמן</label>
            <input name="date" id="dateForm_story" type="text" placeholder="זמן" onChange={(e)=>{setDate(e.target.value)}} /> 
        </div>
        <div className="createStory__unit">
        <label for="contactDescriptionForm_story">טקסט הכתבה</label>
        <ReactQuill
         theme="snow"
          value={storyTextValue} onChange={setStoryTextValue} />
        </div>
        <Select 
        
        className="comboBox"
            options={tagOptions}
            value={storyTags}
            onChange={setStoryTags}
            isMulti
            />

        <div className="createStory__group createStory__group__image  flex-1">  
            <div className="createStory__unit fileUpload  flex-1">
                <input id="fileForm_story" type="file" onChange={(event) => { setStoryImageUpload(event.target.files[0]); }}  />
                <label for="fileForm_story" className="srOnly">בחירת תמונה</label>
                 <div onClick={uploadFile}  className="createStory__uploadFile">תמונת נושא</div> 
            </div>
        </div>
        

        <div className="createStory__unit">
             <label for="contactNameForm_story">שם</label>
            <input type="text" id="contactNameForm_story" placeholder="שם" onChange={(e)=>{setContactName(e.target.value)}} />
        </div>
        <div className="createStory__unit">
               
                <label for="contactDescriptionForm_story">תאור הפרופיל</label>
                <ReactQuill
                    theme="snow"
                    value={contactDescription} onChange={setContactDescription} />
                
            </div>
        
        <div className="flex-1">
            <div className="createStory__unit">
                <label for="contactPhoneForm_story">נייד</label>
                <input type="text" id="contactPhoneForm_story" placeholder="נייד" onChange={(e)=>{setContactPhone(e.target.value)}} />
            </div>
            <div className="createStory__unit">
                <label for="contactEmailForm_story">אימייל</label>
                <input type="text" id="contactPhoneForm_story" placeholder="דואר" onChange={(e)=>{setContactEmail(e.target.value)}} />
            </div>
            <div className="createStory__unit">
                <label for="contactWebForm_story">אתר</label>
                <input type="text" id="contactWebForm_story" placeholder="אתר" onChange={(e)=>{setContactWebsilte(e.target.value)}} />
            </div>

            <div className="createStory__unit">
                <label for="contactFacebook_story">פייסבוק</label>
                <input type="text" id="contactFacebook_story" placeholder="פייסבוק" onChange={(e)=>{setContactFacebook(e.target.value)}} />
            </div>
            <div className="createStory__unit">
                <label for="contactLinkedinForm_story">לינקדאין</label>
                <input type="text" id="contactLinkedinForm_story" placeholder="לינקדאין" onChange={(e)=>{setContactLinkedin(e.target.value)}} />
            </div>
            <div className="createStory__unit">
                <label for="contactXForm_story">X</label>
                <input type="text" id="contactXForm_story" placeholder="x" onChange={(e)=>{setContactX(e.target.value)}} />
            </div>
            <div className="createStory__unit">
                <label for="contactInstagramForm_story">אינסטגרם</label>
                <input type="text" id="contactInstagramForm_story" placeholder="אינסטגרם" onChange={(e)=>{setContactInstagram(e.target.value)}} />
            </div>
        </div>
        <div className="createStory__group createStory__group__image  flex-1">  
            <div className="createStory__unit fileUpload  flex-1">
                <input id="fileForm_story" type="file" onChange={(event) => { setProfileImageUpload(event.target.files[0]); }}  />
                <label for="fileForm_story" className="srOnly">בחירת תמונה</label>
                 <div onClick={uploadProfileImage}  className="createStory__uploadFile"> תמונת פרופיל</div> 
            </div>
        </div>
        <div className="createStory__unit  flex-1">
            <button id="createStory__submit" type="submit" className="createStory__submit">שמירה</button>
       </div>
    </form>
    </div>
    );
};

export default Createstory;