import React , {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useParams} from 'react-router-dom';
import fb from "./firebase";


const DB =fb.firestore()
const storieslist = DB.collection('stories');

const CategoryView = () => {
    const[stories, Setstories] = useState([]);
    const {category} = useParams();
    useEffect(() =>{
        const unsubscribe = storieslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            // Update state
            Setstories(data);
           });
  
          // Detach listener
          return unsubscribe;
    }, []);

    const Deletestory = (id)=> {
        storieslist.doc(id).delete().then(() => {
            alert("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    };
    return(  
        <div>
            {stories.filter(doc => doc.category.includes(category)).map(story=> (
                <div key={story.id}>
                    <p>{story.storyTitle}</p>
                    <p>{story.storyDescription}</p>
                    <Link to={"/show/"+story.id}>View</Link>
                    <Link to={"/Editstory/"+story.id}>Edit</Link>
                    <button 
                        onClick={()=> {Deletestory(story.id)}} 
                    >delete</button>
                    <div>

                    </div>
                </div>
            ))}   
        </div>
    );
};

export default CategoryView;