import React , {useState, useEffect} from "react";
import fb from "./firebase";
import StoriesList from "./storiesList";
import "./storiesList.css";
import { Link} from 'react-router-dom';

const DB =fb.firestore()
const categorieslist = DB.collection('categories');
const StorieslistView = () => {

    const[categories, setCategories] = useState([]);
    useEffect(() =>{
        const unsubscribe = categorieslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const categorydata = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            // Update state
            setCategories(categorydata);
           });
  
          // Detach listener
          return unsubscribe;
    }, []);

    return(
             <div className="categories">
             {/* <nav className='categories__nav'>
             <Link to="#activities">חוגים</Link>
                <Link to="#diagnostics">איבחונים</Link>
                <Link to="#school">מערכת החינוך</Link>
            </nav> */}
                {categories.map(category=> ( 
                    <div key={category.id} className="categories__unit" id={category.id}>
                        <div className="categories__container">
                            <div className="categories__info">
                            <h3 className="categories__title">{category.name}</h3>
                            <p className="categories__description">{category.description}</p>
                            </div>
                                <StoriesList storycategory={category.name} />
                            <div>
                        </div>
                    </div>
                </div> 
                 ))}     
             </div>   
            );};

export default StorieslistView;