
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAFHzt2TTqv_Lrcf_pUL0Nf-9CXIGXCXbw",
  authDomain: "stories-4be9f.firebaseapp.com",
  projectId: "stories-4be9f",
  storageBucket: "stories-4be9f.appspot.com",
  messagingSenderId: "157867616822",
  appId: "1:157867616822:web:c47aca676a6dfc14f28697"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);